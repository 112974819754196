import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./scrollInAnimations.css";

gsap.registerPlugin(ScrollTrigger);

const animateInScroll1 = () => {
  gsap.from(".animateInScroll1", {
    filter: "blur(3px)",
    opacity: 0,
    x: -50,
    ease: "back.out",
    stagger: 0.2,
    duration: 1,
    scrollTrigger: {
      trigger: ".animateInScroll1",
      // scrub: true,
      start: "top 80%",
      end: "top center",
      // markers: true,
    },
  });
};

const projectsAnimation = () => {
  gsap.utils.toArray(".project-animation").forEach((target, index) => {
    gsap.fromTo(
      target,
      {
        x: 200 * (index % 2 == 0 ? -1 : 1),
        opacity: 0,
        filter: "blur(10px)",
      },
      {
        opacity: 1,
        x: 0,
        filter: "blur(0px)",
        scrollTrigger: {
          trigger: target,
          scrub: true,
          start: "top bottom",
          end: "top-=100 40%",
          // markers: true,
        },
      }
    );
  });
};

export { animateInScroll1, projectsAnimation };
