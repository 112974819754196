const projectsData = [
  {
    id: 0,
    name: "Autarchic Pricing Page",
    year: "2023",
    link: "https://het-patel69.github.io/Autarchic/",
    desc: "Autarchic's pricing page redefines user experience with a distinctive plan selector, seamlessly guiding users through personalized choices. Elevate your journey with our innovative design, making subscription selection an intuitive and visually engaging process.",
    img: "img/autarchic-mockup.png",
    colorTheme: "#4fbd34",
  },
  {
    id: 1,
    name: "Abstract Studio Page",
    year: "2023",
    link: "https://het-patel69.github.io/abstract-studio/",
    desc: "Dive into Abstract Studio's Help Center webpage, a testament to my prowess in transforming design files into responsive, user-friendly pages. Impeccable execution and attention to detail shine through, offering users an intuitive and visually striking platform for seamless assistance.",
    img: "img/abstract-mockup.png",
    colorTheme: "#B5b5e3",
  },
  {
    id: 2,
    name: "Trivia App",
    year: "2023",
    link: "https://het-trivia-app.vercel.app/",
    desc: "Experience the Trivia App, a showcase of my React.js expertise, powered by the Trivia API. Witness seamless interactivity and complex state management in action, as the app delivers a captivating trivia experience with a user-friendly interface, demonstrating the depth of my React.js skills.",
    img: "img/trivia-mockup.png",
    colorTheme: "#395e60",
  },
  {
    id: 3,
    name: "Snaeker Store",
    year: "2022",
    link: "https://het-patel69.github.io/sneaker_store/",
    desc: "Discover a sleek sneaker shopping website, a testament to my design and development finesse. Unique aesthetics meet seamless functionality, showcasing my skills in creating an eye-catching and user-friendly online shopping experience.",
    img: "img/sneaker-store-mockup.png",
    colorTheme: "#22272b",
  },
  {
    id: 4,
    name: "Meme Generator",
    year: "2022",
    link: "https://het-memegenerator.vercel.app/",
    desc: "Experience humor at your fingertips with Meme Generator website. Crafted with my adept skills in React.js and API integration, this platform effortlessly blends creativity and technology, allowing users to create memes with ease—an exhibit of my dynamic development capabilities.",
    img: "img/meme.jpeg",
    colorTheme: "#9d4fb8",
  },
];

export default projectsData;
