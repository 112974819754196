import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// From libraries
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Lenis from "@studio-freight/lenis";

// Components
import App from "./App";
import HomePage from "./Pages/HomePage";
import ProjectsPage from "./Pages/ProjectsPage";
import NotFound from "./Pages/NotFound";
import AboutPage from "./Pages/AboutPage";

// CSS
import "./index.css";

const lenis = new Lenis({
  duration: 2,
  // smoothTouch: true,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();