import React, { useEffect } from "react";

import "./Footer.css";
import applyMagneticEffect from "../utils/magneticEffect";

export default function Footer() {
  useEffect(() => {
    applyMagneticEffect();
  });

  return (
    <>
      <section className="contact-section">
        <h2 className="title">Get In Touch</h2>
        <div className="contact-wrapper">
          <div className="contact">
            <div className="prof">
              <p>
                e-mail :{" "}
                <a href="mailto:phet9429@gmail.com">phet9429@gmail.com</a>
              </p>
              <p>Call: +91 9157233711</p>
            </div>
            <div className="socials">
              <a href="https://instagram.com/patelhet_7" target="_blank">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="https://github.com/Het-Patel69" target="_blank">
                <i className="fa-brands fa-square-github"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/het-patel-bb6971214/"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://x.com/Patelhet_7" target="_blank">
                <i className="fa-brands fa-square-x-twitter"></i>
              </a>
            </div>
            <div className="resume">
              <a
                download
                href="https://drive.google.com/file/d/1MaTvysGfh3IYSu_zuQz6rIBS6NOt5CA3/view?usp=drive_link"
              >
                <button className="magneticEffect downloadResumeButton">
                  Resume
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="copyright">
          <p>&copy; 2023 Het Patel. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
}
