import React from "react";
import { NavLink } from "react-router-dom";

import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="not-found-wrapper">
      <div className="not-found">
        <h1>404</h1>
        <h2>
          Page You are looking for does not exist,{" "}
          <NavLink to="/">Go to Home Page</NavLink>
        </h2>
      </div>
    </div>
  );
}
