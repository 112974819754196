const applyMagneticMenu = () => {
  if (document.body.getAttribute("mobileOrTablet") == "true") {
    return;
  }

  const magneticMenu = document.querySelectorAll(".magneticMenu");
  const powerConfig = {x1: 0.2, y1: 0.2, x2: 0.3, y2: 0.3}

  magneticMenu.forEach((btn) => {
    btn.addEventListener("mousemove", function (e) {
      const position1 = btn.getBoundingClientRect();
      const x1 = e.clientX - position1.left - position1.width / 2;
      const y1 = e.clientY - position1.top - position1.height / 2;


      const position2 = btn.children[0].getBoundingClientRect();
      const x2 = e.clientX - position2.left - position2.width / 2;
      const y2 = e.clientY - position2.top - position2.height / 2;

      btn.style.transform = "translate(" + x1 * powerConfig.x1 + "px, " + y1 * powerConfig.y1 + "px)";
      btn.children[0].style.transform = "translate(" + x2 * powerConfig.x2 + "px, " + y2 * powerConfig.y2 + "px)";
    });
  });

  magneticMenu.forEach((btn) => {
    btn.addEventListener("mouseout", function (e) {
      btn.style.transform = "translate(0px, 0px)";
      btn.children[0].style.transform = "translate(0px, 0px)";
    });
  });
};

export default applyMagneticMenu;