import React, { useEffect } from "react";

import Project from "../Components/Project";

import "./ProjectWrapper.css";

import projectsData from "../data/projectsData";
import { projectsAnimation } from "../Animation/scrollInAnimations";

export default function ProjectsWrapper({ animation, count = 999, color = false}) {
  useEffect(() => {
    if (animation) {
      projectsAnimation();
    }
  });

  const projects = projectsData.map((project, index) => {
    if (index + 1 <= count) {
      return <Project key={project.id} project={project} color={color}/>;
    }
  });

  return <div className="projects-wrapper">{projects}</div>;
}
