import React, { useEffect } from "react";

import ProjectsWrapper from "../Components/ProjectsWrapper";

import mobileAndTabletCheck from "../utils/checkMobileAndTablet";
import applyHackedTextEffect from "../utils/hackedTextEffect";
import applySparkleText from "../utils/sparkleText";
import applyMagneticEffect from "../utils/magneticEffect";
import applyMouseEffect from "../utils/mouseEffect";
import applyMouseFollow from "../utils/mouseFollowRotate";

import "./HomePage.css";

// gsap animations
import { animateInScroll1 } from "../Animation/scrollInAnimations";
import { floatingTitleScrolling } from "../Animation/scrollOutAnimations";
import { NavLink } from "react-router-dom";
import scrollToTop from "../utils/scrollToTop";
export default function HomePage() {
  useEffect(() => {
    mobileAndTabletCheck();
    applyHackedTextEffect();
    applySparkleText();
    applyMagneticEffect();
    applyMouseEffect();
    applyMouseFollow();

    floatingTitleScrolling();
    animateInScroll1();
  });

  return (
    <>
      <main>
        <section className="intro-wrapper mouseEffect">
          <div className="intro-text followMouse">
            <p className="intro-text-im depth-1">Hello, I'm</p>
            <h1 className="intro-text-name depth-2">
              <span className="name-first hackedTextEffect" data-value="Het">
                Het
              </span>
              <span className="name-second hackedTextEffect" data-value="Patel">
                Patel
              </span>
            </h1>
            <div className="intro-text-desc depth-3">
              <p>Transforming ideas into reality.</p>
              <p>
                I create
                <span className="magic">
                  <span className="magic-star">
                    <svg viewBox="0 0 512 512">
                      <path d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z" />
                    </svg>
                  </span>
                  <span className="magic-star">
                    <svg viewBox="0 0 512 512">
                      <path d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z" />
                    </svg>
                  </span>
                  <span className="magic-star">
                    <svg viewBox="0 0 512 512">
                      <path d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z" />
                    </svg>
                  </span>
                  <span className="magic-text">mesmerizing</span>
                </span>
                User Interfaces.
              </p>
            </div>
          </div>
          <div className="wand">
            <div className="cap"></div>
          </div>
          <div className="floating-title-Wrapper">
            <div className="floating-title">
              <span data-scroll-speed="1.3">Web</span>
              <span data-scroll-speed="1">Developer</span>
            </div>
            <div className="floating-title">
              <span data-scroll-speed="0.6">UI</span>
              <span data-scroll-speed="0.7">Developer</span>
            </div>
            <div className="floating-title">
              <span data-scroll-speed="1.3">Problem</span>
              <span data-scroll-speed="1.5">Solver</span>
            </div>
          </div>
          <div className="custom-shape-divider-bottom-1700892787 shape-divider">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <defs>
                <linearGradient
                  id="myGradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="var(--gradientC1)" />
                  <stop offset="100%" stopColor="var(--gradientC2)" />
                </linearGradient>
              </defs>
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </section>
        <section className="what-i-do-section">
          <div className="what-i-do-wrapper">
            <h2 className="title animateInScroll1">What Do I Do?</h2>
            <p className="text animateInScroll1">
              I design and develop visually Elegent Web Applications with
              Seamless Functionality.
            </p>
            <p className="text animateInScroll1">
              If you like awesome Projects and are not into boring stuff, If you
              want a modern and elegent Website, You have reached the right
              person.
              <a className="mail-link" href="mailto:phet9429@gmail.com">
                Write to me
              </a>
              and let's connect.
            </p>
          </div>
          {/* <div className="custom-shape-divider-bottom-1700908248 shape-divider">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"
              ></path>
            </svg>
          </div> */}
        </section>
        <section className="projects-section">
          <div className="custom-shape-divider-top-1701353953 shape-divider">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <defs>
                <linearGradient
                  id="myGradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="var(--gradientC1)" />
                  <stop offset="100%" stopColor="var(--gradientC2)" />
                </linearGradient>
              </defs>
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
          <div className="projects-section-content-wrapper">
            <h2 className="title">Recent Projects</h2>
            <ProjectsWrapper animation={true} count={3} />
            <div className="project-link-wrapper">
              <NavLink
                to="/projects"
                className="magneticEffect project-link"
                onClick={() => {
                  scrollToTop()
                }}
              >
                View All Projects
              </NavLink>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
