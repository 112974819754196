export default function scrollToTop() {
  setTimeout(() => {
    // window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    // document
    //   .querySelector("body")
    //   .scrollTo({ top: 0, left: 0, behavior: "instant" });
    // document.querySelector("body").scrollTop = 0;

    document.getElementById("topRef").scrollIntoView();
  }, 1);
}
