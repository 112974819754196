import React from "react";

import "./AboutPage.css";

export default function AboutPage() {
  return (
    <main className="about-main">
      <div className="about-content-wrapper">
        <section className="section-aboutMe">
          <h2 className="title">About Me</h2>
          <div className="profile">
            <div className="profile-header">
              <p className="profile-header-name">Het Patel</p>
              <p>Web developer</p>
              <p>Ahmedabad, India</p>
            </div>
            <div className="profile-text">
              I am a student & a developer currently pursuing bachelor in the
              field of Information Technology. I have strong interest in web
              technologies and love working on projects and turning ideas into
              relaity. When i'm not working or learning, you will probably find
              me watching Anime.
            </div>
          </div>
        </section>
        <section className="section-exp">
          <h2 className="title">Experience</h2>
          <div className="experience-wrapper">
            <div className="experience">
              <p className="title">
                Intern - The Bridge Code | Jul 2023 - Nov 2023
              </p>
              <p>
                Worked as an intern at The Bridge Code in Frontend web
                development.
              </p>
              <p>
                Specialized in frontend development, with a focus on UI
                development using React.js. I transformed design concepts into
                interactive components, ensuring seamless user experiences
                across various devices
              </p>
            </div>
            <div className="experience">
              <p className="title">
                Intern - Praxware Technologies | Jan 2023 · 1 mo
              </p>
              <p>
                Worked as an intern at Praxware Technologies for one month in
                android application development.
              </p>
            </div>

            <div className="experience">
              <p className="title">Intern - Coding Gujju | Jun 2022 · 1 mo</p>
              <p>
                Worked as an intern at Coding Gujju for one month in web
                development.
              </p>
              <p>
                Learned basics of web development, Node.js, and React.js, and
                applied them to an HTML format.
              </p>
            </div>
          </div>
        </section>
        <section className="section-skills">
          <h2 className="title">Skills</h2>
          <div className="skillsWrapper">
            <div className="skills-languages skill-div">
              <p className="skill-title">languages</p>
              <p>Javascript | C | C++ | Python | Java | PHP | SQL | Kotlin</p>
            </div>
            <div className="skills-tt skill-div">
              <p className="skill-title">Technologies & Tools</p>
              <p>React.js | Node.js | Express.js | Android Studio | Figma</p>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}
