const applyHackedTextEffect = () => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const targetsNodelist = document.querySelectorAll(".hackedTextEffect");
  const targets = Array.from(targetsNodelist);
  
  targets.forEach((target) => {
      let interval = null;
      target.onmouseover = (event) => {
      let iteration = 0;

      clearInterval(interval);

      interval = setInterval(() => {
        event.target.innerText = event.target.innerText
          .split("")
          .map((letter, index) => {
            if (index < iteration) {
              return event.target.dataset.value[index];
            }

            return letters[Math.floor(Math.random() * 26)];
          })
          .join("");

        if (iteration >= event.target.dataset.value.length) {
          clearInterval(interval);
        }

        iteration += 1 / 3;
      }, 30);
    };
  });
};

export default applyHackedTextEffect;