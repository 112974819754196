import React, { useEffect } from "react";

import "./Project.css";

export default function Project(props) {
  return (
    <div
      className="project project-animation"

      style={props.color?{ "--color": props.project.colorTheme }:{}}
    >
      <div className="project-head">
        <div className="project-name">{props.project.name}</div>
        <div className="project-info">
          <p className="project-year">{props.project.year}</p>
        </div>
      </div>
      <div className="project-body">
        <div className="project-desc">
          <div className="description">{props.project.desc}</div>
          <a className="project-link" href={props.project.link} target="_blank">
            View Project
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        </div>
        <div className="project-image">
          <img src={props.project.img} alt="" />
        </div>
      </div>
      <div className="project-background"></div>
    </div>
  );
}
