const applyMagneticEffect = () => {
  if (document.body.getAttribute("mobileOrTablet") == "true") {
    return;
  }

  const targets = Array.from(document.querySelectorAll(".magneticEffect"));
  const powerConfig = { x1: 0.3, y1: 0.3 };

  targets.forEach((btn) => {
    btn.addEventListener("mousemove", function (e) {
      const position1 = btn.getBoundingClientRect();
      const x1 = e.clientX - position1.left - position1.width / 2;
      const y1 = e.clientY - position1.top - position1.height / 2;

      btn.style.transform =
        "translate("+x1 * powerConfig.x1 +"px, " +y1 * powerConfig.y1 +"px)";
    });
  });

  targets.forEach((btn) => {
    btn.addEventListener("mouseout", function (e) {
      btn.style.transform = "translate(0px, 0px)";
    });
  });
};

export default applyMagneticEffect;