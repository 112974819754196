import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import "./scrollOutAnimations.css";

gsap.registerPlugin(ScrollTrigger);

const floatingTitleScrolling = () => {
  const targets = Array.from(document.querySelectorAll(".floating-title span"));
  targets.forEach((target) => {
    gsap.to(target, {
      y: -300 * target.dataset.scrollSpeed,
      // opacity: 0,
      filter: "blur(0.5rem)",
      ease: "none",
      scrollTrigger: {
        scrub: target.dataset.scrollSpeed,
      },
    });
  });
};

const fadeWhenScroll = () => {
  gsap.utils.toArray(".fadeScrollOut").forEach((target) => {
    gsap.to(target, {
      opacity: 0,
      ease: "none",
      scrollTrigger: {
        scrub: true,
      },
    });
  });
};

export { fadeWhenScroll, floatingTitleScrolling };
