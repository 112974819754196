import React, { useLayoutEffect } from "react";

import "./ProjectPage.css";
import ProjectsWrapper from "../Components/ProjectsWrapper";
import scrollToTop from "../utils/scrollToTop";

export default function ProjectsPage() {
  useLayoutEffect(() => {
    scrollToTop();
  });
  return (
    <main className="projects-main">
      <h1 className="title">Featured Projects</h1>
      <section className="featured-projects">
        <ProjectsWrapper animation={true} color={true} />
      </section>
    </main>
  );
}
